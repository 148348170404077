import React, {useEffect} from "react"
import {hasWindow, isBrowserSupported} from "./utils";
import {orderBy} from "lodash";
import Layout from "./components/Layout";
import BrowserNotSupported from "./components/not-supported/BrowserNotSupported";
import Block from "./components/Block";
import {DataContext, CaptchaProvider, useAnalytics} from "dealer-website-components";
import {graphql} from 'gatsby';
import settings from "../config";
import Helmet from "react-helmet";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import CustomBlock from "./components/CustomBlock";

const Page = (props) => {
    const {page, meta, favoriteIconUrl, componentContext} = props.pageContext;
    const {t} = useTranslation()
    const context = {...componentContext, t: t}
    const {analyticsEventSubmit, pageImpressionEventInitialize} = useAnalytics(context);

   // console.log(context)

    const analyticsPageImpression = () => {
        // Ignore on SRR
        if (hasWindow()) {
            // vehicleDetail needs more data, all other pages send data imediatly
            if (page.originalUrl !== componentContext.urls.vehicleDetail) {
                analyticsEventSubmit(pageImpressionEventInitialize())
            }
        }
    }

    const resolveAnalyticsData = (type, data) => {
        analyticsEventSubmit(pageImpressionEventInitialize(type, data));
    }

    useEffect(() => {
        analyticsPageImpression();
    }, []);


    const browserSupported = isBrowserSupported();
    if (!browserSupported) {
        return (
            <div className="font-body bg-gray-100">
                <BrowserNotSupported/>
            </div>)
    }


    const orderedBlocks = orderBy(page.blocks, 'order');


    return (
        <Layout context={componentContext}>
            <CaptchaProvider captchaKey={settings.CAPTCHA_KEY}>
                <DataContext.Consumer>
                    {value => (

                        <React.Fragment>

                            <Helmet>
                                <title>{meta.title}</title>
                                <meta name="description" content={meta.description}/>
                                <link rel="icon" href={favoriteIconUrl}/>

                                <script>
                                    {`window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', '${settings.GOOGLE_ANALYTICS_ID}');`}
                                </script>

                                <script>
                                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','${settings.GOOGLE_TAG_MANAGER_ID}');`}
                                </script>

                            </Helmet>


                            {orderedBlocks.map((block) => {
                                if (block.contentType !== 'CustomGeneratedBlockInBuilder') {
                                    return <Block block={block}
                                                  key={block.id}
                                                  context={componentContext}
                                                  store={value}
                                                  resolveAnalyticsData={resolveAnalyticsData}
                                    />
                                } else {
                                    return <CustomBlock block={block}
                                                        key={block.id}
                                                        context={componentContext}
                                    />
                                }

                            })}
                        </React.Fragment>
                    )}
                </DataContext.Consumer>
            </CaptchaProvider>
        </Layout>

    )
};

export default Page;

export const query = graphql`
query($language: String!)
    {
        locales: allLocale(filter
    :
        {
            language: {
                eq: $language
            }
        }
    )
        {
            edges
            {
                node
                {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
