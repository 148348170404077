import React from "react";
import {find} from "lodash";

const CustomBlock = ({block, context}) => {

        const getHtmlContent = (data, lng) => {
            const content = find(data, (d) => d.culture === lng);
            const html = (content) ? find(content.fields, (f) => f.type === 'html') : undefined;
            return (html) ? html.value : "content not found";
        }

        return (
            <div id={`b${block.id.split('-')[0]}`}>
                <div dangerouslySetInnerHTML={{__html: getHtmlContent(block.content, context.culture)}}/>
            </div>
        )
    }
;

export default CustomBlock;

