import React from "react";
import {getKeyValueContentFieldsForBlock} from "../utils"
import * as components from 'dealer-website-components'

const Block = ({block, context, store, overridePageMetaData, resolveAnalyticsData}) => {
        const Component = components[block.variant];

        if (!Component) {
            return null;
        }

        return (
            <div id={`b${block.id.split('-')[0]}`}>
                <Component  {...getKeyValueContentFieldsForBlock(block, context.culture)}
                            {...context.properties}
                            context={context}
                            store={store}
                            overridePageMetaData={overridePageMetaData}
                            resolveAnalyticsData={resolveAnalyticsData}
                />
            </div>
        )
    }
;

export default Block;

